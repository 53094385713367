/* Basic styles for the proposal accordion */
.proposal-list {
  margin-top: 20px;
}

.proposal-item {
  /*border: 1px solid #ccc;*/
  margin-bottom: 10px;
  border-radius: 4px;
  overflow: hidden;
}

.proposal-header {
  display: flex;
  justify-content: space-between;
  background-color: #f1f1f1;
  padding: 10px;
  cursor: pointer;
  align-items: center;
}

.proposal-header h3 {
  margin: 0;
}

.proposal-header span {
  font-size: 20px;
}

.proposal-details {
  padding: 10px;
  background-color: #fff;
}

.proposal-details p {
  margin: 5px 0;
}

.proposal-details button {
  margin-top: 10px;
}

.proposal-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
}

.proposal-content {
  padding: 18px;
  background-color: #f4f8fb;
  border-top: 1px solid #ccc;
}

.filter-options {
  margin-bottom: 15px;
}

.radiospace {
  margin-left: 25px;
}
.voterwallet {
  font-size: 14px;
}

.filter-options button {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.filter-options button:hover {
  background-color: #0056b3;
}

.reaction-buttons {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  margin-right: 10px;
}

.reaction-buttons:disabled {
  color: gray;
  cursor: not-allowed;
}

.vote-yes {
  vertical-align: middle;
  color: green;
}
.vote-yes-v {
  vertical-align: middle;
  color: green;
  margin-bottom: 5px;
  font-size: 22px;
  margin-left: 10px;
}

.vote-no {
  vertical-align: middle;
  color: red;
}

.vote-no-v {
  vertical-align: middle;
  color: red;
  margin-bottom: 4px;
  font-size: 22px;
  margin-left: 10px;
}

.claim-rewards-button, .report-button {
  background-color: #3ab857;
  color: white;
  border: none;
  font-size: 14px;
  font-weight: 400;
  padding: 0.5rem 1rem;
  margin-top: 10px;
  width: 220px;
  cursor: pointer;
}

.report-button:disabled {
  background-color: gray;
}

.claim-rewards-button:hover, .report-button:hover {
  background-color: #218838;
}
.castvote-button {
  display: inline-block; /* Make it behave like a button */
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  background: rgba(125, 167, 240, 0.44);
  color: #0B4182;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  text-decoration: none; /* Remove underline from the link */
  border: none;
  font-weight: 400;
  margin-top: 10px;
  cursor: pointer;
  width: 220px;
}
.castvote-button:hover {
  transition: all 0.3s ease-in-out;
  color: #ffffff;
  background: var(--seccolor3);
}

.history-button {
  background-color: #2e72c5;
  color:#fff;
  border: none;
  font-size: 14px;
  font-weight: 400;
  padding: 0.5rem 1rem;
  margin-top: 10px;
  cursor: pointer;
  width: 220px;
}
.history-button:hover {
  background-color: var(--maincolor);
  color:#fff;
}

.flag-icon {
  cursor: pointer;
  font-size: 20px;
  color: #d9534f; /* Customize the color to suit your theme */
}

.reporters-list {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
}

.reporters-list h4 {
  margin-bottom: 5px;
}

.reporters-list ul {
  list-style: none;
  padding-left: 0;
}

.reporters-list li {
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
