:root {
  --maincolor: #0b4182;
  --seccolor: #2C3E50;
  --maincolor1: #00AEEF;
  --seccolor1: #BDC3C7;
  --maincolor2: #2ECC71;
  --secolor2:#E67E22;
  --maincolor3: rgba(11, 65, 130);
  --maincolor4: rgb(11, 130, 92);
  --seccolor3: rgba(125, 167, 240);
  --primary: rgba(0, 0, 0, 1);
  --secondary: rgba(0, 0, 0, 0.7);
  --tertiary: rgba(0, 0, 0, 0.5);
}

.App {
  text-align: center;
  font-family: 'Ubuntu', 'Arial', sans-serif;
}

body {
  background-size: cover;
  font-size: 1rem;
  font-family: "Manrope", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: var(--secondary);
  background-attachment: fixed;
  background-color: #F4F8FB;
  padding-top: 59px;
  /*background-color: #f2f2f2;*/
  height: 100vh;
}

a,
.a {
  color: var(--primary);
  font-weight: 400;
  transition: all 0.2s ease-in-out;
}

a:hover,
.a:hover {
  color: var(--tertiary);
}

hr {
  opacity: 0.06;
  margin: 20px 30px !important;
}

p,
.p {
  color: var(--secondary);
}

b,
.b {
  color: var(--primary);
}
.bstep {
  color: var(--seccolor1) !important;
  font-weight: 600;
}

h1,
.h1 {
  margin-bottom: 0;
}

h2,
.h2 {
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 0px;
  font-size: 28px;
  font-weight: 600;
  color: #0B4182;
  text-transform: uppercase;
}

@media screen and (max-width: 400px) {

  h2,
  .h2 {
    font-size: 24px;
  }
}

h3,
.h3 {
  text-align: left;
  width: 100%;
  display: block;
  margin-top: 0px;
  font-size: 24px;
  font-weight: 600;
  color: #34495E;
  text-transform: uppercase;
}

@media screen and (max-width: 400px) {

  h3,
  .h3 {
    font-size: 20px;
  }
}

h4,
.h4 {
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 20px;
  /*text-transform: uppercase;*/
  color: #0B4182;
}

h5,
.h5 {
  color: #34495E;
  font-weight: 300 !important;
  margin-bottom: 4px;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 2px;
}

h6,
.h6 {
  color: #34495E;
  font-weight: 300 !important;
  font-size: 15px;
  margin-bottom: 4px;
}

@media screen and (max-width: 400px) {

  h6,
  .h6 {
    font-size: 14px;
  }
}

.card {
  position: relative;
  width: auto;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);*/
  border: 0.5px solid rgba(255, 255, 255, 0.5);
}

.card-fix {
  position: relative;
  overflow: hidden; /* Prevent content overflow */
  width: auto;
  min-width: auto;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);*/
  border: 0px solid transparent;
}

.card-fix .card-img-top {
  height: 140px !important;
  padding: 0px;
  object-fit: fill;
}

.blur {
  filter: blur(3px);
  -webkit-filter: blur(3px);
}

.pokemon-card-id small {
  font-size: 10px;
}

.pokemon-card-id {
  display: block;
  min-width: 40px;
  background-color: var(--seccolor);
  border-radius: 9px;
  color: #fff;
  font-size: 15px;
  position: absolute;
  top: 6px;
  left: 6px;
  text-align: center;
  line-height: 25px;
  /*border: 1px solid var(--maincolor);*/
  background: var(--maincolor);
  transition: all 0.5s;
  padding: 0px 15px;
}

.glass-card {
  position: relative;
  width: 300px;
  /* Adjust the width as needed */
  padding: 20px;
  margin: 20px;
  background: linear-gradient(0deg, rgba(125, 167, 240, 0.72) 0%, rgba(125, 167, 240, 0) 100%);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /*border: 1px solid rgba(11, 65, 130, 0);*/
  color: white;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.referral-card {
  background: linear-gradient(0deg, rgba(125, 167, 240, 0.17) 0%, rgba(125, 167, 240, 0) 100%);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  /*border: 0px solid var(--maincolor);
  border-color: var(--seccolor);
  border-radius: 10px;*/
  color: rgba(0, 0, 0, 0.7);
  transition: all 0.5s ease-in-out;
  position: relative;
}

.pokemon-card {
  /* background: linear-gradient(0deg, rgba(125, 167, 240, 0.17) 0%, rgba(125, 167, 240, 0) 100%); */
  background: linear-gradient(to left,rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.6));
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  /*border: 0px solid var(--maincolor);
  border-color: var(--seccolor);*/
  border-radius: 10px;
  /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);*/
  color: rgba(0, 0, 0, 0.7);
  padding: 34px 30px;
  transition: all 0.5s ease-in-out;
  position: relative;
}


.nft-card {
  padding: 34px 30px;
}

.nft-card-left {
  padding: 0 0 0 60px;
}

.nft-card-right {
  padding: 0 60px 0 0;
}

@media screen and (max-width: 800px) {
  .pokemon-card {
    padding: 26px 20px;
  }

  .nft-card {
    padding: 26px 20px;
  }

  .additional-p {
    padding: 32px 28px;
  }

  .nft-card-left {
    padding: 0;
  }

  .nft-card-right {
    padding: 0;
  }
}

.info-img {
  height: 160px;
  width: 160px;
}

.pokemon-card-height {
  height: 575px;
}

.card-height {
  /*height: 370px;*/
  min-height: 100%;
  /*max-height: 444px;*/
}

@media screen and (max-width: 768px) {

  .pokemon-card-height {
    height: unset;
  }

  .card-height {
    height: unset;
  }
}

.card-img-top {
  object-fit: fill;
  /*height: 300px;
  /* Set a fixed height for your images */
  border-radius: 10px;
  mix-blend-mode: multiply;
}

.card-img {
  object-fit: cover;
  height: 260px;
  width: 260px;
  /* Set a fixed height for your images */
  border-radius: 10px;
  mix-blend-mode: multiply;
}

.card-text {
  font-size: 15px;
  line-height: 1.7;
}

.pokemon-card-title {
  text-align: center;
  border-radius: 10px;
  padding: 10px 0;
  font-weight: 800;
  font-size: 25px;
  background-color: transparent;
  color: var(--primary);
  border-bottom: 0px solid transparent;
}

.pokemon-card-body {
  padding: 10px;
}

.card-padding {
  padding: 1rem 1.4rem 0.4rem;
}

@media screen and (max-width: 768px) {

  .card-padding {
    padding: 6px 6px 4px;
  }

}

.pokemon-card-footer {
  padding: 26px 0 0;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: transparent;
}

button {
  background: var(--maincolor);
}

.buton {
  background: transparent;
  border: 0px solid transparent;
  border-radius: 5px;
  color: #0B4182;
  display: inline-block;
  width: 100%;
  text-decoration: none;
  padding: 10px 16px;
  transition: all 0.3s ease-in-out;
  background: rgba(125, 167, 240, 0.44);
  min-width: 40px;
  font-size: 15px;
  font-weight: 400;
  /*box-shadow: 0px 2px 10px rgba(148, 77, 255, 0.3);*/
}

.buton:hover {
  transition: all 0.3s ease-in-out;
  color: #ffffff;
  background: var(--seccolor3);
  /*border: 0px solid var(--maincolor);*/
}

.buton1 {
  background: transparent;
  border: 0px solid transparent;
  border-radius: 8px;
  color: #ffffff;
  display: inline-block;
  width: 100%;
  text-decoration: none;
  padding: 10px 16px;
  transition: all 0.3s ease-in-out;
  background: rgba(125, 167, 240, 0.44);
  min-width: 40px;
  font-size: 15px;
  font-weight: 400;
  /*box-shadow: 0px 2px 10px rgba(148, 77, 255, 0.3);*/
}
.buton1:hover {
  transition: all 0.3s ease-in-out;
  color: #ffffff;
  background: var(--maincolor2);
  /*border: 0px solid var(--maincolor);*/
}

.buton2 {
  background: transparent;
  border: 0px solid transparent;
  border-radius: 8px;
  color: #ffffff;
  display: inline-block;
  width: 100%;
  text-decoration: none;
  padding: 10px 16px;
  transition: all 0.3s ease-in-out;
  background: rgba(125, 167, 240, 0.44);
  min-width: 40px;
  font-size: 15px;
  font-weight: 400;
  /*box-shadow: 0px 2px 10px rgba(148, 77, 255, 0.3);*/
}
.buton2:hover {
  transition: all 0.3s ease-in-out;
  color: #ffffff;
  background: var(--seccolor2);
  /*border: 0px solid var(--maincolor);*/
}
.butonDisabled {
  border: 0px;
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: var(--maincolor) !important;
}

.buton:disabled {
  border: 0px;
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: #FFFFFF !important;
}

.buton-small {
  border: 0px solid transparent;
  border-radius: 50%;
  color: var(--maincolor);
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(148, 77, 255, 0.1);
  width: 40px;
  height: 40px;
  font-size: 24px;
  font-weight: 400;
  margin: 0 auto;
  margin-top: 10px;
  box-shadow: none;
}


.buton-secondary {
  background: rgba(125, 167, 240, 0.46);
  /*color: rgba(148, 77, 255, 1);*/
  color:#0B4182;
  border-radius: 5px;
  height: 36px;
  box-shadow: none;
  padding: 6px 6px;
  font-size: 14px;
  font-weight: 400;
}

.buton-sec-small {
  font-size: 11px;
}

.buton-secondary:hover {
  color: rgba(255, 255, 255);
  background: var(--seccolor3);
  box-shadow: none;
}


.buton-terciary {
  background: rgba(125, 167, 240, 0.46);
  /*color: rgba(148, 77, 255, 1);*/
  color:#0B4182;
  border-radius: 5px;
  height: 26px;
  box-shadow: none;
  padding: 9px 9px;
  font-size: 11px;
  font-weight: 400;
}

.buton-terc-small {
  font-size: 11px;
}

.buton-terciary:hover {
  color: rgba(255, 255, 255);
  background: rgba(46, 204, 113, 0.72);
  box-shadow: none;
}

.buton-craft {
  background: rgba(46, 204, 113, 0.72);
  /*color: rgba(148, 77, 255, 1);*/
  color:#0B4182;
  border-radius: 5px;
  border-color: rgba(46, 204, 113, 0.72);
  /*height: 36px;*/
  box-shadow: none;
  padding: 9px 9px;
  font-size: 14px;
  font-weight: 500;
}

.buton-craft-small {
  font-size: 11px;
}

.buton-craft:hover {
  color: rgba(255, 255, 255);
  background: rgba(46, 204, 112, 1);
  border-color: rgba(125, 167, 240, 0.46);
  box-shadow: none;
}

.btn-success {
  /*text-transform: uppercase;*/
  --bs-btn-border-color: #7da7f09b;
  display: inline-block; /* Make it behave like a button */
  padding: 5px 20px;
  font-size: 1rem;
  background: rgba(125, 167, 240, 0.44);
  color: #0B4182;
  font-weight: 200;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  text-decoration: none; /* Remove underline from the link */
}

.btn-outline-secondary{
  padding: 5px 20px;
  font-size: 1rem;
}

.btn-success:hover {
  background-color: #7da7f0 !important;

}

.btn-success:disabled {
  background-color: rgba(0, 0, 0, 0.1) !important;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.4) !important;
  font-weight: 400;
}

.buton:disabled {
  background-color: rgba(0, 0, 0, 0.1) !important;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.4) !important;
  font-weight: 400;
}


.loaderSmall {
  display: block;
  color: var(--seccolor3);
  width: 20px;
  height: 20px;
  margin: 0 auto;
  margin-top: 0px;

}

.loaderBig {
  color: var(--seccolor3);
  width: 100px;
  height: 100px;
  margin-top: 100px;

}

.loaderMsg {
  color: var(--seccolor3);
  font-size: 18px;
  margin-top: 50px;
  font-weight: 200;
}

.status-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.green-circle {
  background-color: #01a201;
}

.buy-token-container {
  color: white;
  width: 100%;
  max-width: 100%;
  margin: auto;
  box-shadow: 0px 2px 10px rgba(149, 77, 255, 0.3);
  border-radius: 10px;
  padding: 20px;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}

.buy-token-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media screen and (max-width: 400px) {
  .buy-token-header {
    margin-bottom: 8px;
  }

  .header-margin {
    margin-bottom: 14px;
  }
}

.buy-node,
.buy-token {
  background: #252e4f;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.token-exchange-info {
  padding: 14px 10px 18px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 20px;
  font-weight: 900;
}

.wallet-balance {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.wallet-button {
  background: #313c5e;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.balance-info {
  padding: 17px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 20px;
}

@media screen and (max-width: 400px) {
  .token-exchange-info {
    margin-bottom: 10px;
  }

  .wallet-balance {
    margin-bottom: 10px;
  }

  .balance-info {
    margin-bottom: 10px;
  }
}

.swap-form {
  border-radius: 10px;
}

.input-group {
  margin-bottom: 20px;
}

.input-group input,
.input-group select {
  font-size: 15px;
  color: var(--tertiary);
  ;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(149, 77, 255, 0.01);
  padding: 6px 16px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.input-group input:focus-visible {
  color: var(--secondary);
  border: 2px solid var(--secondary);
  background: rgba(149, 77, 255, 0.01);
}

.input-group input:hover {
  color: var(--secondary);
  border: 2px solid gba(149, 77, 255, 0.01);
  background: rgba(149, 77, 255, 0.01);
}

.input-group-prop input,
.input-group-prop select {
  font-size: 15px;
  color: var(--tertiary);
  ;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fafafa;
  padding: 6px 16px;
  border-radius: 8px;
  max-width: 360px;
  text-align: justify;
  transition: all 0.3s ease-in-out;
}

.balanceDisplay {
  display: inline-block;
  color: var(--primary);
  font-weight: 700;
  font-size: 16px;
  padding: 0px;
  border-radius: 10px;
  margin-bottom: 0px;
  margin-right: 20px;
  width: 100%;
}

@media screen and (max-width: 400px) {
  .balanceDisplay {
    margin-right: 0px;
    padding: 24px 14px;
  }
}

.balanceDisplay img {
  width: 30px;
  margin-right: 8px;
}

.max-button {
  background: transparent;
  color: var(--maincolor);
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}


.gas-info {
  font-size: 14px;
  padding: 12px 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  color: #777;
  opacity: 2.5;
}

.swap-button {
  background: var(--seccolor);
  color: white;
  border: none;
  padding: 10px 20px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
}

.top-bar {
  background: #5261DF;
  /*box-shadow: rgb(0, 0, 0, 0.15) 10px 0px 20px;*/
  padding: 10px 0;
  /*margin-bottom: 5px;*/
  position: fixed;
  width: 100%;
  height: 86px;
  top: 0px;
  border-bottom: 0.05px solid #bac8e3;  
  z-index: 1000; /* Ensure it's above other content */
}

.menu {
  display: flex;
  align-items: center;
  padding-left: 0px;
}

@media screen and (max-width: 800px) {
  .menu {
    padding-left: 0px;
  }
}

.menuBtn {
  padding: 20px 30px 16px;
  margin: 0 10px !important;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  background-color: transparent;
  border: 0px;
  margin-right: 0px;
  color: var(--tertiary);
  /*text-transform: uppercase;*/
  transition: all 0.5s ease-in-out;
  box-shadow: none;
  width: auto;
}

.menuBtn svg {
  margin-top: -3px;
  margin-right: 7px;
  font-size: 18px;
}

.menuBtn:hover {
  color: var(--primary);
  background-color: transparent;
  border: 0px;
}

@media screen and (max-width: 800px) {
  .menuBtn {
    padding: 16px 10px 10px;
    font-size: 12px;
  }
}

@media screen and (max-width: 390px) {
  .menuBtn {
    padding: 16px 10px 10px;
    font-size: 11.5px;
  }
}

.dropdown-padding {
  padding: 12px 0 8px
}

@media screen and (max-width: 992px) {
  .dropdown-padding {
    padding: 8px 10px 2px
  }
}

.connectButton {
  background-color: #01a201 !important;
}

.tokenIconColor {
  color: var(--maincolor);
  ;
}

.loaderScreen {
  display: block;
  height: 100%;
  width: 100%;
  background-color: transparent;
  position: absolute;
  top: 0px;
  left: 0px;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
}

.alert {
  padding: 20px;
}


.smallBtn {
  display: block;
  width: auto;
  padding: 6px 25px;
  margin-left: 6px;
  border-radius: 6px;
  float: right;
}


.plusButton {
  display: block;
  border: 0px;
  margin: 0 auto;
  background: var(--maincolor);
  border-radius: 10px;
  padding: 30px 10px;
  color: #FFFFFF !important;
  transition: all 0.3s ease-in-out;
  box-shadow: none;
}

.plusButton:hover {
  box-shadow: none;
  background: var(--maincolor);
  transition: all 0.3s ease-in-out;
  box-shadow: rgb(195, 195, 195) 0px 0px 20px;
}

.plusButton p {
  color: #FFFFFF !important;
  font-weight: 500;
  transition: all 0.5s ease-in-out;
  margin-bottom: 12px;
}

.plusButton img {
  width: 20%;
  margin-bottom: 20px;
  margin-top: 20px;
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-top: 160px;
  /* 1:1 Aspect Ratio */
}

.iframe-container-small {
  position: relative;
  width: 70px;
  padding-top: 80px;
  display: inline-block;
  margin-left: -25px;
  margin-top: -70px;
  margin-right: 20px;
}

@media screen and (max-width: 400px) {
  .iframe-container-small {
    margin-left: -20px;
  }
}

.iframe-container iframe {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
  max-height: 280px;

}

.iframe-container-small iframe {
  position: absolute;
  top: 35px;
  left: 10px;
  width: 100%;
  height: 100%;
  border: none;
  /* Optionally, to remove iframe border */
  border-radius: 10px;
  z-index: 100;
}

w3m-button {
  margin: 0 auto;
  color: var(--maincolor) !important;
}

wui-text {
  color: red;
}

.progress,
.progress-stacked {
  --bs-progress-bar-bg: rgba(125, 167, 240);
  --bs-progress-bg: rgba(125, 167, 240, 0.72);
}

.progress-bar {
  color: transparent !important;
}

.node-item {
  display: flex;
  align-items: center;
  padding: 20px 30px;
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  border: 0px solid var(--maincolor);
  border-color: var(--seccolor);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.7);
  transition: all 0.5s ease-in-out;
  position: relative;
}

.node-item:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 400px) {
  .node-item {
    padding: 24px 14px;
  }
}

.node-image img {
  width: 50px;
  /* Adjust the size based on your design */
  height: 50px;
  margin-right: 15px;
  /* Provides spacing between the image and the text */
}

.node-info {
  display: flex;
  flex-grow: 1;
  align-items: center;
  /* Align text content vertically in the center */
}

.node-id,
.node-name,
.node-rewards {
  margin-right: 15px;
  /* Provides spacing between text elements */
}

.node-id {
  font-size: 0.9rem;
  color: #333333;
  font-weight: bold;
}

.node-name {
  font-size: 1.1rem;
  color: #333333;

}

.node-rewards {
  font-size: 0.9rem;
  color: #999999;
  flex-grow: 1;
  /* Allows this element to take up any available space */
}

.card-balance{
  color: #0B4182;
  font-weight: 500;
}

.market-header{
  color: #0B4182;
  font-weight: 400;
}

.market-sub-header{
  color: #0B4182;
  font-weight: 400;
  font-size: 14px;
}

.craft-header{
  color: #0B4182;
  font-weight: 500;
  font-size: 14px;
}

button {
  padding: 5px 10px;
  background-color: var(--maincolor);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.btn-copy {
  background: rgba(0, 0, 0, 0.04);
  box-shadow: none;
  font-size: 0.8125rem;
  border-radius: 100px;
  padding: 6px 20px;
  color: var(--tertiary);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  font-weight: 400;
  height: 30px;
}

.btn-copy:hover {
  background: rgba(0, 0, 0, 0.08);
  color: var(--tertiary);
}

.custom-select {
  border-radius: 10px !important;
  background-color: #fafafa !important;
}

.custom-select:focus {
  outline: none;
  /* Remove focus outline */
  border-color: var(--tertiary);;

}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

button.pulse-button {
  animation: borderPulse 1200ms infinite ease-out;
}

@keyframes borderPulse {
  0% {
    box-shadow: inset 0px 0px 0px 5px rgba(227, 219, 254, .4), 0px 0px 0px 0px rgba(148, 77, 255, 0.4);
  }

  100% {
    box-shadow: inset 0px 0px 0px 3px rgba(148, 77, 255, .2), 0px 0px 0px 10px rgba(148, 77, 255, 0);
  }
}


.table {
  --bs-table-bg: transparent;
}

.alert-warning {
  padding: 10px;
  border-radius: 10px;
  font-weight: 600;
}

.rounded-pill {
  /*background-color: rgba(148, 77, 255, 0.7) !important;*/
  padding: 8px 8px;
  font-size: 12px;
  font-weight: 600;
  display: block;
}

.pill-blue {
  background-color: rgba(77, 95, 255, 0.7) !important;
}

.pill-pink {
  background-color: rgba(237, 77, 255, 0.7) !important;
}

.bg-success {
  background-color: rgba(53, 182, 62, 0.8) !important;
  color: #FFFFFF;
}

.bg-danger {
  background-color: rgba(255, 191, 0, 0.8) !important;
}

.progress {
  background-color: rgba(133, 133, 133, 0.13);
}

.backgroundDecoration {
  position: absolute;
  z-index: -1;
  width: 70%;
  left: 15%;
  top: 25%;
  opacity: 0.3;
}

.height50 {
  height: 50px !important;
  max-height: 50px !important;
}

.height70 {
  height: 70px !important;
  max-height: 70px !important;
}

.height80 {
  height: 85px !important;
  max-height: 86px !important;
}

.modal-content {
  border-radius: 10px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px 0 25px;
  background-color: rgba(255, 255, 255, 0.9);
}

.frame-card {
  height: 300px;
  border-radius: 10px;
  padding: 20px;
  padding-top: 50px;
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  display: block;
  margin-bottom: 30px;
}


.navbar-toggler {
  border: 0px;
}

.custom-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.custom-card .card-title {
  font-size: 23px;
  font-weight: 600;
  color: #0B4182;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.custom-card .card-text {
  font-size: 15px;
  color: var(--secondary);
}

.list-group-item {
  border: none;
  padding: 10px 0;
}


.assistant-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.assistant-button {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.assistant-icon {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.assistant-button:hover {
  background-color: transparent;
}

.chat-message {
  white-space: pre-wrap;
  margin-bottom: 10px;
  background: linear-gradient(0deg, rgba(125, 167, 240, 0.17) 0%, rgba(125, 167, 240, 0) 100%);
  padding: 10px;
  width: 100%;
}
.card-chat {
  padding-left: 30px;
}

.tokenCardId {
  background: var(--maincolor);
  opacity: 0.4;
  font-weight: 800;
  color: #FFFFFF;
  border-radius: 10px 0px 10px 0px;
  padding: 5px 10px;
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 10px;
}

.navbar-nav .dropdown-menu {
  background: #f0f0f0 !important;
  padding: 10px 0;
  border: 0px solid transparent;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  color: var(--tertiary);
}

@media screen and (max-width: 768px) {

  .navbar-nav .dropdown-menu {
    box-shadow: none;
  }
}
.navbar-bkg {
  background-color: #5261DF;
}

.dropdown-menu .nav-link {
  color: var(--maincolor);
  font-size: 13px;
  font-weight: 400;
}

@media screen and (max-width: 800px) {
  .dropdown-menu .nav-link {
    font-size: 12px;
  }
}

@media screen and (max-width: 390px) {
  .dropdown-menu .nav-link {
    font-size: 11.5px;
  }
}

.dropdown-menu .nav-link:hover {
  color: var(--seccolor);
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.bg-secondary {
  background-color: rgba(0, 0, 0, 0.15) !important;
}
/*
.scrollable-row {
  overflow-x: unset;
  white-space: nowrap;
  scrollbar-color: var(--maincolor);
  scrollbar-width: thin;
  padding-bottom: 15px;
}

@media screen and (max-width: 768px) {

  .scrollable-row {
    overflow-x: scroll;
  }
}

.scrollable-row .col {
  flex: 0 0 auto;
}
.scrollable-row .row {
  display: flex;
  flex-wrap: nowrap;
  /*padding-right: 20px;
  padding-left: 20px;*/
/*}*/

.scrollable-row {
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent the items from wrapping to the next line */
  padding-bottom: 10px; /* Optional: space for scrollbar */
  padding-left: 10px; /* Optional: space for scrollbar */
}

.scrollable-row .row {
  display: flex;
  flex-wrap: nowrap; /* Ensure all items are in a single row */
  gap: 30px; /* Space between columns
}

.scrollable-row .col {
  flex: 0 0 auto; /* Prevent columns from stretching */
  width: 100%; /* Full width by default */
}

@media (min-width: 576px) {
  .scrollable-row .col {
    width: 50%; /* Half width on small screens (sm) */
  }
}

@media (min-width: 768px) {
  .scrollable-row .col {
    width: 33.33%; /* One-third width on medium screens (md) */
  }
}

@media (min-width: 992px) {
  .scrollable-row .col {
    width: 33.33%; /* One-third width on large screens (lg) */
  }
}

.level-card {
  text-align: center; /* Center align content inside the card */
  /*margin-right: 15px; /* Optional: space between items */
}



.levelImg {
  width: 90%;
}

.levelTitle {
  cursor: pointer;
  background: var(--seccolor3);
  width: auto;
  color: #FFFFFF;
  position: absolute;
  z-index: 1;
  bottom: 18px;
  left: 34px;
  padding: 5px 15px 10px 20px;
  border-radius: 5px;
  font-size: 20px;
}

.levelTitle small {
  font-size: 12px;
}

.level-pill {
  background: var(--seccolor);
  color: #FFFFFF;
  padding: 5px 18px 5px 18px;
  border-radius: 30px;
  font-size: 20px;
  text-transform: capitalize;
}

.level-pill-big {
  background: var(--seccolor);
  color: #FFFFFF;
  padding: 15px 18px;
  border-radius: 30px;
  font-size: 25px;
  text-transform: capitalize;
  margin-top: 0px;
}

.level-pill small {
  font-size: 12px;
}

.level-card {
  position: relative;
  cursor: pointer;
}

.navbar-brand {
  margin-right: 0px;
}

.navbar-toggler {
  color: transparent !important;
}

.nav-link {
  color: #ffffff;
}

.nav-link:focus, .nav-link:hover {
  color: #23E89C;
  font-weight: 500;
}

.modal-header {
  border-bottom: 0px solid transparent;
  padding: 1rem 2rem 0;
  align-items: center !important;
}

.modal-footer {
  border-top: 0px solid transparent;
  padding: 1.5rem 2rem;
}

.modal-body {
  border-bottom: 0px solid transparent;
  padding: 1rem 2rem 0;
}

@media screen and (max-width: 600px) {
  .modal-header {
    padding: 0.5rem 1rem 0;
  }

  .modal-footer {
    padding: 1rem 1rem;
  }

  .modal-body {
    padding: 0.5rem 1rem 0;
  }
}

.alert-secondary {
  background: transparent;
  border: 0px;
  box-shadow: none;


}

.iframe-containerr {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  /* Aspect ratio 16:9 */
}

.iframe-containerr iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.loaderCard {
  padding-top: 150px;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10000;
}

.card-balance {
  /*height: 252px;*/
}

.card-title {
  font-size: 23px;
}

.card-balance-r {
  height: 252px;
}

@media screen and (max-width: 600px) {
  .card-title {
    font-size: 16px;
  }

  .card-balance-r {
    height: unset;
  }
}

.modal-header .btn-close {
  position: absolute;
  top: 28px;
  right: 24px;
  background-color: transparent;
}

.btn-close:focus {
  box-shadow: none;
}

.footer {
  padding: 10px 0;
  width: 100%;
  bottom: 0;
  position:fixed;
  font-size: 14px;
  background: #f0f0f0;
  box-shadow: rgb(0, 0, 0, 0.1) 10px 0px 16px;
  z-index: 999;
}

.footer-link {
  color: #777777;
  margin: 0 15px;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
  color: var(--maincolor);
  font-weight: 400;
}


.footer-content {
  display: flex;

  text-align: center;
}

.footer-links a,
.footer-social a {
  margin: 0 10px;
}

.footer-copy {
  margin: 0;
  padding-top: 10px;
}
/* Responsive styles */
@media (min-width: 768px) {
  .footer-content {
    flex-direction: row; /* Arrange items horizontally on larger screens */
    justify-content: space-between; /* Space out columns */
  }
}


.footer-links {
  margin-left: 20px;
  display: flex;
  gap: 15px; /* Adds spacing between the links */
}

.footer-links .footer-link {
  margin-left: 15px;
}
.footer-links .footer-link:hover {
  text-decoration: underline;
}

.social-icon {
  margin: 0 10px;
  color: rgba(0, 0, 0);
  transition: all 0.3s;
  text-decoration: none;
  opacity: 0.6;
}

@media screen and (max-width: 400px) {
  .social-icon {
    margin: 0 8px;
    font-size: 13px;
  }
}

.twitter:hover {
  opacity: 1;
}

.telegram:hover {
  color: #0088cc;
  opacity: 1;
}

.instagram:hover {
  color: #E1306C;
  opacity: 1;
}

.platform:hover {
  color: #0b4182;
  opacity: 0.9;
}

.station:hover {
  color: rgb(237, 77, 255);
  opacity: 0.8;
}

.footer-social .social-icon {
  margin: 0 10px;
  color: #555; /* Default color */
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

.footer-social .social-icon:hover {
  transform: scale(1.2); /* Slightly enlarge on hover */
}

/* Individual hover colors */
.footer-social .social-icon.telegram:hover {
  color: #0088cc; /* Telegram blue */
}

.footer-social .social-icon.twitter:hover {
  color: #1da1f2; /* Twitter blue */
}

.footer-social .social-icon.youtube:hover {
  color: #ff0000; /* YouTube red */
}

.footer-social .social-icon.medium:hover {
  color: #00ab6c; /* Medium green */
}

.footer-social .social-icon.coinmarketcap:hover {
  color: #0052ff; /* CoinMarketCap blue */
}

.accordion {
  border-radius: 8px;
  --bs-accordion-bg: rgba(125, 167, 240, 0.228);
  --webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  border: 0px solid transparent !important;
  --bs-accordion-border-color: transparent !important;
}

.accordion-header {
  border-radius: 8px;
  --bs-accordion-bg: rgba(125, 167, 240, 0.876);
  border: 0px solid transparent;
}

.accordion-button {
  color: var(--maincolor1);
  font-weight: 500 !important;
  font-size: 16px;
  padding: 26px 28px;
  background-color: transparent !important;
}

.accordion-body {
  border-radius: 8px !important;
  padding: 0px 28px 10px;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: #284e96 !important;
}

.accordion-item:first-of-type>.accordion-header .accordion-button {
  border-radius: 8px !important;
}

.accordion-item {
  border-radius: 8px !important;
  font-size: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

@media screen and (max-width: 400px) {
  .accordion-button {
    font-size: 15px;
    padding: 22px;
  }

  .accordion-body {
    padding: 0px 22px 6px;
  }

  .accordion-item {
    font-size: 14px;
  }
}

.craft-item {
  border-radius: 10px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  background-color: rgba(255, 255, 255, 0.9);
}


/* src/components/FlipNotification.css */
.notification {
  font-family: 'Courier New', Courier, monospace;
  font-size: 20px;
  background: var(--maincolor);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  color: var(--maincolor1);
  padding: 10px;
  margin: 0px;
  text-align: center;
  border-radius: 0px;
  font-weight: 800;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.notification-test{
  background: var(--maincolor2) !important;
  color:#FFFFFF !important;
}

.test-button{
  background-color: var(--maincolor2) !important;
  color:#FFFFFF !important;
}

.flipped {
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flipped .front,
.flipped .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.flipped .front {
  transform: rotateY(0deg);
}

.flipped .back {
  transform: rotateY(180deg);
}


@keyframes float {
  0% {

    transform: translatey(0px) rotateZ(0deg);

  }

  25% {

    transform: translatey(0px) rotateZ(5deg);
  }

  50% {

    transform: translatey(0px) rotateZ(-10deg);
  }

  75% {

    transform: translatey(0px) rotateZ(5deg);
  }

  100% {

    transform: translatey(0px) rotateZ(0deg);
  }
}

.floatAvatar {
  animation: float 6s ease-in-out infinite;
}

.refAccordion .accordion-button {
  /*height: 46px;*/
  color: rgba(250, 250, 250);
  box-shadow: none;
  padding: 10px 16px;
  font-size: 16px;
  text-align: center;
  font-weight: 500 !important;
}

.refAccordion .accordion-item {
  background: rgba(125, 167, 240)
}


.hight100 {
  height: 100vh; /* Takes full viewport height */
}

.pageWelcome {
  font-size: 16px;
  font-weight: 400;
  color: #0B4182;
  margin-bottom: 30px;
  margin-top: 40px;
  text-align: justify;
}
.pageModal {
  font-size: 14px;
  font-weight: 400;
  color: #0B4182;
  text-align: justify;
}
.pageWelcome-header{
  font-size: 20px;
  font-weight: 600;
  color: #0B4182;
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: left;
}
.pageWelcome-sub-header{
  font-size: 18px;
  font-weight: 500;
  color: #0B4182;
  text-align: left;
}
.pageWelcome-center {
  font-size: 18px;
  font-weight: 600;
  color: #0B4182;
  margin-bottom: 30px;
  margin-top: 40px;
  text-align: justify;
  /* Flexbox for vertical centering */
  display: flex;
  justify-content: center;
  align-items: center;
}
.pageWelcome-text {
  font-size: 16px;
  font-weight: 400;
  color: var(--secondary);
  margin-bottom: 30px;
  margin-top: 40px;
  text-align: justify;
}

.video-introduction {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: #0b4182 !important;
}

.css-z15j16-control:hover {
  border-color: #0b4182 !important;
  box-shadow: none !important;
}

.css-1ymg8of-control {
  border-color: #0b4182 !important;
  box-shadow: none !important;
}

.qwrlds-card {
  min-width: "250px !important"
}

@media screen and (max-width: 400px) {
  .qwrlds-card {
    min-width: "unset"
  }
}

/* Reverse the animation direction */
.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite reverse;
}
/* Ensure custom-select dropdown is correctly positioned */
.custom-select {
  position: relative;
  width: 100%; /* Ensure it takes the full width of its container */
  font-size: 14px; 
  padding: 10px;
  z-index: 998; /* Ensure it appears above other content */
  background-color: #fafafa !important;
}

/* Ensure dropdown list is positioned correctly */
.custom-select:focus {
  outline: none;
  box-shadow: none; 
  position: relative;
}

/* Handle dropdown appearance on small screens */
@media (max-width: 576px) {
  .custom-select {
      font-size: 16px;
      padding: 10px;
      width: 100%;
      position: relative;
      z-index: 1050;
  }

  /* Ensure parent container does not clip the dropdown */
  .input-group, .pokemon-card {
      overflow: visible !important;
  }

  /* Adjust the position of the dropdown list */
  .custom-select option {
      position: relative;
      z-index: 1050;
  }
}

.marquee-container {
  width: 140px; /* Fixed width of 140px */
  height: 80px; /* Adjust this height based on your image height */
  overflow: hidden;
  position: relative;
  margin: 0 auto; /* Center the marquee within the card */
}

.marquee {
  display: flex;
  animation: marquee 10s linear infinite; /* Adjust the duration for speed */
  align-items: center; /* Center images vertically if they don't take full height */
}

.marquee-image {
  min-width: 100%;
  transition: transform 0.5s ease;
  height: auto; /* Adjust this if needed to fit within the container */
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.image-slider {
  width: 95%;
  height: 97px; /* Adjust height as necessary */
  position: relative;
  overflow: hidden;
}

.slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slider-image.show {
  opacity: 1;
}

.centered-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.container {
    padding: 15px;
}

@media (min-width: 768px) {
    .card-fix {
        margin-left: auto;
        margin-right: auto;
    }
}

.card-body h6 {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
}

/* Card container styles */
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Card styles */
.engine-card {
  width: 100%;
  max-width: 480px;
  height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  background: linear-gradient(0deg, rgba(125, 167, 240, 0.17) 0%, rgba(125, 167, 240, 0) 100%);
  border-radius: 8px;
  overflow: hidden;
  padding: 16px;
  position: relative; /* Ensures absolute positioning within the card */
}

/* Logo field styles */
.engine-logo {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: left top; /* Aligns the image to the top left */
  display: block; /* Ensures it behaves like a block element */
}

/* Description field styles */
.engine-description {
  height: 200px; /* Fixed height */
  margin-top: 8px; /* Reduced margin to position it closer to the first row */
  margin-bottom: 20px; /* 20px margin from the button */
  font-size: 14px;
  line-height: 1.4;
  text-align: justify;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 8; /* Limits text to fit within the fixed height */
  -webkit-box-orient: vertical;
  word-break: break-word;
}

/* Referral button styles */
.referral-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 52px);
}
/* Alias and Address styles */
.engine-alias {
  padding-left: 10px;
  padding-right: 10px;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Aligns the content to the right */
}

.engine-alias p,
.engine-alias a {
  margin: 0;
  text-align: right; /* Ensures the text is right-aligned */
}

/* General styles for rows within the card */
.engine-card .row {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

/* Row for the logo and alias */
.engine-card .align-items-center {
  margin-bottom: 8px; /* Ensure there's a small gap between the rows */
}

/* Description row */
.engine-card .engine-description {
  height: 200px; /* Fixed height */
  margin-top: 0; /* Align directly under the first row */
 /* padding: 0 10px; /* Padding on left and right */
  font-size: 14px;
  line-height: 1.4;
  text-align: justify;
  overflow-y: auto; /* Make it scrollable if there is more content */
  word-break: break-word;
}

.table-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.table-cell {
  padding: 6px 0;
  flex-grow: 1;
}

@media (max-width: 576px) {
  .table-cell {
    padding: 6px 5px;
  }
}

.affiliateFee {
  font-size: 16px;
  font-weight: 400;
  color: #0B4182;
  margin-bottom: 6px;
  margin-top: 8px;
  text-align: justify;
}

.affiliate-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px; /* Adds spacing between items */
}

.affiliateFee {
  margin-bottom: 0; /* Removes bottom margin for consistent spacing */
  white-space: nowrap; /* Prevents text from wrapping to the next line */
}

@media (max-width: 768px) {
  .affiliate-container {
    flex-direction: column; /* Stacks items vertically on small screens */
    align-items: flex-start;
  }
}

.flip-notification-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1100; /* Ensure it's above the navbar */
}
asset-name {
  font-weight: bold;
  color: black;
}

.asset-symbol {
  color: grey;
}

.dimmed-content {
  opacity: 0.5;
  pointer-events: none;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

.loading-spinner span {
  margin-top: 10px;
  color: #007bff;
  font-size: 16px;
}

.copy-button {
  background: none;
  border: none;
  cursor: pointer;
  /*padding-top: 0.5 rem;
  padding-bottom: 0.5 rem;*/
  display: flex;
  align-items: center;
  justify-content: center;
}

.copy-button .icon {
  color: grey;
  font-size: 1.2em;
  font-weight: 100;
}

.copy-button:hover .icon {
  color: darkgrey; /* Optionally change color on hover */
}

.no-border-bottom tr {
  border-bottom: none !important; /* Force removal of bottom border */
}

.no-border-bottom td {
  border-bottom: none !important; /* Ensure no bottom border on table cells */
  vertical-align: middle; /* Vertically align the content to the middle */
}
.add-token-button {
  background: none; /* Remove background */
  border: none; /* Remove border */
  padding: 0; /* Remove padding */
  cursor: pointer; /* Change cursor to pointer */
}

.add-token-button img {
  display: inline-block; /* Ensure proper alignment */
  width: 18px; /* Adjust image size */
  height: auto;
}

.add-token-button:hover img {
  opacity: 0.8; /* Slightly reduce opacity on hover for effect */
}

.add-token-button:focus {
  outline: none; /* Remove outline when the button is focused */
}

.justify {
  text-align: justify;
}

.tokenIconColor {
  width: 18px;
  margin-right: 5px;
}

.craft-section, .buy-section {
  width: 100%; /* Ensure sections take full width */
}

@media (max-width: 768px) {
  .buton-secondary {
    font-size: 14px; /* Adjust button font size for smaller screens */
  }
}


.craftNumber{
  border-radius: 0px 0px 10px 10px;
  display: block;
  color: #FFFFFF;
  background-color: var(--seccolor3);
  width: 100%;
  opacity: 0.9;
  text-align: center;
  margin: 0 auto;
  margin-top: -20px;
  position: relative;
}

.custom-modal-backdrop {
  background-color: rgba(0, 0, 0, 0.3); /* Adjust the 0.5 to control opacity */
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dim background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top of other content */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%; /* Set width to 90% for small screens */
  max-width: 600px; /* Set maximum width for larger screens */
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow-y: auto; /* Add scroll if content overflows */
}

.modal-content-header {
  color: #0B4182;
  text-align: center;
  font-weight: 600;
}

/* Ensure the modal content adjusts for small screens */
@media (max-width: 768px) {
  .modal-content {
    width: 95%; /* Adjust width to fit smaller screens */
    padding: 15px; /* Reduce padding for smaller screens */
  }
}

.modal-content h2 {
  margin-bottom: 16px;
}

.modal-content p {
  margin-bottom: 24px;
}

.modal-content button {
  padding: 6px 20px;
  background-color: rgba(125, 167, 240, 0.44);
  color: #0B4182;
  border: none;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
}

.modal-content button:hover {
  background-color: var(--seccolor3);
}


.chat-message-modal {
  white-space: pre-wrap;
  margin-bottom: 10px;
  background: linear-gradient(0deg, rgba(125, 167, 240, 0.72) 0%, rgba(125, 167, 240, 0) 100%);
  padding: 10px;
  width: 100%;
}

.pageTitle-modal {
  font-size: 26px;
  font-weight: 600;
  color: #0B4182;
  margin-bottom: 30px;
  margin-top: 10px;
  text-align: center;
}


.network-info{
  margin-top: 15px;
}

/* Hero Section Styling */
.hero {
  background: linear-gradient(0deg, rgba(125, 167, 240, 0.22) 0%, rgba(125, 167, 240, 0) 100%); 
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  color: rgba(0, 0, 0, 0.7);
  transition: all 0.5s ease-in-out;
  padding: 50px 20px 50px; 
  text-align: center;
  margin-top: 70px; 
  position: relative; 
  width: 100%;
  overflow: hidden; 
}


.hero-content {
  max-width: 800px;
  margin: auto;
  position: relative;
  z-index: 1; 
  text-align: center;
  color: #fff; 
  padding: 20px;
}

.hero-h1 {
  font-family: 'Ubuntu', sans-serif; /* Specify the Ubuntu font */
  font-weight: 600; /* Set the weight to Medium */
  font-size: 3rem;
  margin-bottom: 20px;
  color: #0b4182;
}

.hero-p {
  font-family: 'Rubik', sans-serif; /* Specify the Rubik font */
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  text-align: center;
}
.modal-p {
  font-family: 'Rubik', sans-serif; /* Specify the Rubik font */
  font-size: 1rem;
  font-weight: 300;
  color: var(--secondary);
  letter-spacing: 0.5px;
  text-align: center;
}


.hero-button {
  display: inline-block; /* Make it behave like a button */
  padding: 5px 20px;
  font-size: 0.9rem;
  background: rgba(125, 167, 240, 0.44);
  color: #0B4182;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  text-decoration: none; /* Remove underline from the link */
}

.hero-button:hover {
  transition: all 0.3s ease-in-out;
  color: #ffffff;
  background: var(--seccolor3);
}

.home {
  text-align: center;
  font-family: 'Ubuntu', 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  max-width: 100%; /* Set a maximum width */
  margin: auto; /* Center the app */
}

/* Cards Section Styling */
.wcard-section {
  padding: 0; /* Remove padding on the section */
}

.wcard {
  display: flex; /* Enable flexbox on the card */
  flex-direction: column; /* Arrange children in a column */
  justify-content: center; /* Center items vertically */
  align-items: center; /* Center items horizontally */
  border: none; /* No border for cards */
  border-radius: 8px; /* Rounded corners */
  padding: 15px; /* Padding inside the card */
  transition: transform 0.3s; /* Add a transition for hover effect */
  width: 260px;
}

.wcard-one {
  background: linear-gradient(0deg, rgba(125, 167, 240, 0.72) 0%, rgba(125, 167, 240, 0) 100%); /* Background for Card 1 */
  margin-left: 40px;
}

.wcard-two {
  background: linear-gradient(0deg, rgba(125, 167, 240, 0.72) 0%, rgba(125, 167, 240, 0) 100%);  /* Background for Card 2 */
}

.wcard-three {
  background: linear-gradient(0deg, rgba(126, 243, 153, 0.77) 0%, rgba(126, 243, 153, 0) 100%); /* Background for Card 3 */
}

.wcard-v1 {
  background: linear-gradient(0deg, rgba(126, 243, 153, 0.77) 0%, rgba(126, 243, 153, 0) 100%); /* Background for Card 3 */
}

.wcard-v2 {
  background: linear-gradient(0deg, rgba(226, 228, 228, 0.77) 0%, rgba(226, 228, 228, 0) 100%); /* Background for Card 3 */
}

.wcard-v3 {
  background: linear-gradient(0deg, rgba(236, 216, 148, 0.77) 0%, rgba(236, 216, 148, 0) 100%); /* Background for Card 3 */
}

/* Responsive Styles */
@media (min-width: 992px) { /* Large screens */
  .wcard-section .row > div:nth-child(1) {
    margin-bottom: 60px; /* Increased gap between Card 1 and Card 2 */
  }

  .wcard-section .row {
    padding-left: 0; /* Remove left padding */
    padding-right: 0; /* Remove right padding */
  }
}

/* Small screens */
@media (max-width: 767px) { /* Small screens */
  .wcard-section .row {
    margin-left: 0; /* No margin */
    margin-right: 0; /* No margin */
    padding: 0; /* No padding */
  }

  .wcard {
    margin: 0; /* Ensure no margin on cards */
    width: 100%; /* Full width for small screens */
  }
}

.wcard-button {
  display: inline-block; /* Make it behave like a button */
  padding: 5px 10px;
  font-size: 0.8rem;
  color: #0b4182;
  background: var(--seccolor3);
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  text-decoration: none; /* Remove underline from the link */
}

.wcard-button:hover {
  transition: all 0.3s ease-in-out;
  background: rgba(11, 65, 130);
  color: #ffffff;
}

.wcard-v1-button {
  display: inline-block; /* Make it behave like a button */
  padding: 5px 20px;
  font-size: 0.8rem;
  color: #495760;
  background: #BABABA;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  text-decoration: none; /* Remove underline from the link */
}

.wcard-v1-button:hover {
  transition: all 0.3s ease-in-out;
  background: #495760; 
  color: #ffffff;
}
.wcard-v3-button {
  display: inline-block; /* Make it behave like a button */
  padding: 5px 20px;
  font-size: 0.8rem;
  color: #826c23;
  background: #ecd179;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  text-decoration: none; /* Remove underline from the link */
}

.wcard-v3-button:hover {
  transition: all 0.3s ease-in-out;
  background: #D4AF37;
  color: #ffffff;
}

.wcard-three button {
  display: inline-block; /* Make it behave like a button */
  padding: 5px 20px;
  font-size: 0.8rem;
  color: rgb(29, 119, 50);
  background: rgb(39, 240, 86);

  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  text-decoration: none; /* Remove underline from the link */
}

.wcard-three-button:hover {
  transition: all 0.3s ease-in-out;
  background: rgb(40, 167, 69);
  color: #ffffff;
}

.wcard-image {
  width: 65%; /* Full width to ensure responsiveness */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Rounded corners for a smoother look */
  margin-bottom: 15px; /* Space below the image */
  object-fit: cover; /* Cover the area while maintaining aspect ratio */
}
.wcard-image-v1 {
  width: 65%; /* Full width to ensure responsiveness */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Rounded corners for a smoother look */
  margin-bottom: 15px; /* Space below the image */
  object-fit: cover; /* Cover the area while maintaining aspect ratio */
}
.wcard-image-v2 {
  width: 65%; /* Full width to ensure responsiveness */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Rounded corners for a smoother look */
  margin-bottom: 15px; /* Space below the image */
  object-fit: cover; /* Cover the area while maintaining aspect ratio */
}
.wcard-image-v3 {
  width: 65%; /* Full width to ensure responsiveness */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Rounded corners for a smoother look */
  margin-bottom: 15px; /* Space below the image */
  object-fit: cover; /* Cover the area while maintaining aspect ratio */
}
.logo {
  width: 140px; /* Full width to ensure responsiveness */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Cover the area while maintaining aspect ratio */
}

/* Roadmap Section Styles */
.horizontal-timeline {
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent wrapping of items */
  margin-top: 40px; /* Space above the timeline */
  padding-bottom: 20px; /* Space at the bottom */
}

.horizontal-timeline .items {
  border-top: 3px solid #e9ecef; /* Top border for the timeline */
  margin-top: 40px; /* Space between header and timeline */
  display: inline-block; /* Ensure items are inline for horizontal scrolling */
}

.horizontal-timeline .items .items-list {
  display: inline-block; /* Make items align horizontally */
  position: relative;
  text-align: center;
  padding-top: 70px; /* Padding for spacing */
}

/* Event Date Badge */
.horizontal-timeline .items .items-list .event-date {
  position: absolute;
  top: 36px; /* Position below the timeline */
  left: 0;
  right: 0;
  width: 75px; /* Width of the badge */
  margin: 0 auto; /* Center the badge */
  font-size: 0.9rem; /* Font size for the date */
  padding-top: 8px; /* Space above the date */
}


/* Adjust styles for different statuses */
.horizontal-timeline .items .items-list.completed .event-date {
  background-color: #28a745; /* Green */
}

.horizontal-timeline .items .items-list.in-progress .event-date {
  background-color: #ffc107; /* Yellow */
}

.horizontal-timeline .items .items-list.planned .event-date {
  background-color: #17a2b8; /* Blue */
}

.horizontal-timeline .items .items-list:before {
  content: "";
  position: absolute;
  height: 36px; /* Height of the vertical line */
  border-right: 2px dashed #dee2e6; /* Dashed line */
  top: 0; /* Start at the top */
}

.horizontal-timeline .items .items-list .event-date {
  position: absolute;
  top: 36px; /* Position below the timeline */
  left: 0;
  right: 0;
  width: 75px; /* Width of the badge */
  margin: 0 auto; /* Center the badge */
  font-size: 0.9rem; /* Font size for the date */
  padding-top: 8px; /* Space above the date */
}

/* Adjust styles for larger screens */
@media (min-width: 1140px) {
  .horizontal-timeline .items .items-list {
    display: inline-block; /* Display in a row */
    width: auto; /* Allow auto width for items */
    padding-top: 45px; /* Adjust padding */
  }

  .horizontal-timeline .items .items-list .event-date {
    top: -40px; /* Adjust position for larger screens */
  }
}


/* Add this to App.css */
.particles {
  position: absolute; /* Position particles absolutely */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  right: 0; /* Align to the right */
  bottom: 0; /* Align to the bottom */
  pointer-events: none; /* Prevent mouse events */
}

.matrix-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure the effect is behind the content */
}



/* FAQ */
.wfaq {
  padding: 40px 20px; /* Adjust padding for the FAQ section */
  background-color: var(--bgcolor); /* Set the background color of the FAQ section */
}

.accordion-button {
  background-color: var(--bgcolor);
  border: none; /* Remove default border */
  color: #0B4182; /* Text color */
}

.accordion-button:hover,
.accordion-button:focus {
  background-color: #d9e7ff; /* Light blue background on hover */
  color: #0B4182; /* Ensure text color remains */
}

.accordion-body {
  padding: 15px; /* Padding inside the accordion body */
  background-color: var(--bgcolor); /* Remove background color for body */
  text-align: left;
}

/* Remove borders and shadows */
.accordion-item {
  border: none; /* Remove border */
}

.accordion-item:last-child .accordion-body {
  border-bottom: none; /* Remove border from last item's body */
}

.accordion-button:focus {
  z-index: 3;
  outline: 0;
  /* box-shadow: var(--bs-accordion-btn-focus-box-shadow);*/
}

.alert {
  padding: 20px;
}


.text-center-three {
  font-weight: 600; /* Make the text bold */
  font-family: 'Ubuntu', sans-serif; /* Specify the Ubuntu font */
}

.ampersand {
  padding-left: 10px;  /* Add left padding to the "&" */
  padding-right: 10px; /* Add right padding to the "&" */
}
.read-more-container {
  min-height: 40px; /* Minimum height to keep layout consistent */
  display: flex;
  justify-content: center; /* Center the button horizontally */
  align-items: center; /* Center the button vertically */
}

.read-more-container {
  min-height: 40px; /* Ensures consistent height across items */
  display: flex;
  justify-content: center; /* Center the button horizontally */
  align-items: center; /* Center the button vertically */
  margin-bottom: 20px; /* Adds space below the button */
}

.empty-button {
  visibility: hidden; /* Hide the button, but maintain the same height */
  margin-bottom: 20px; /* Ensures the same spacing below items without a button */
}




.justify {
  text-align: justify;
}

s-container {
  padding: 15px;
}
.no-border-bottom tr {
  border-bottom: none !important; /* Force removal of bottom border */
}

.no-border-bottom td {
  border-bottom: none !important; /* Ensure no bottom border on table cells */
  vertical-align: middle; /* Vertically align the content to the middle */
}
.add-token-button {
  background: none; /* Remove background */
  border: none; /* Remove border */
  padding: 0; /* Remove padding */
  cursor: pointer; /* Change cursor to pointer */
}

.add-token-button img {
  display: inline-block; /* Ensure proper alignment */
  width: 18px; /* Adjust image size */
  height: auto;
}

.add-token-button:hover img {
  opacity: 0.8; /* Slightly reduce opacity on hover for effect */
}

.add-token-button:focus {
  outline: none; /* Remove outline when the button is focused */
}
.copy-button {
  background: none;
  border: none;
  cursor: pointer;
  /*padding-top: 0.5 rem;
  padding-bottom: 0.5 rem;*/
  display: flex;
  align-items: center;
  justify-content: center;
}

.copy-button .icon {
  color: grey;
  font-size: 1.2em;
  font-weight: 100;
}

.copy-button:hover .icon {
  color: darkgrey; /* Optionally change color on hover */
}

.table {
  --bs-table-bg: transparent;
}
.table-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.table-cell {
  padding: 6px 0;
  flex-grow: 1;
}

@media (max-width: 576px) {
  .table-cell {
    padding: 6px 5px;
  }
}
.refresh-button {
  display: inline-block; /* Make it behave like a button */
  padding: 10px 20px;
  font-size: 1.2rem;
  background: rgba(125, 167, 240, 0.44);
  color: #0B4182;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  text-decoration: none; /* Remove underline from the link */
}
.pageTitle {
  font-size: 26px;
  font-weight: 400;
  color: #0B4182;
  margin-bottom: 30px;
  margin-top: 50px;
  text-align: center;
  text-transform: none;
  font-family: 'Ubuntu', 'Arial', sans-serif;
}
.pagesubTitle {
  font-size: 26px;
  font-weight: 600;
  color: #0B4182;
  margin-top: 40px;
  text-align: center;
}
.roadmap-header {
  text-align: center;
  text-transform: none;
  font-family: 'Ubuntu', 'Arial', sans-serif;
}
.crosschain-header {
  text-align: center;
  text-transform: none;
  font-family: 'Ubuntu', 'Arial', sans-serif;
}
.supplies-header {
  text-align: center;
  text-transform: none;
  font-family: 'Ubuntu', 'Arial', sans-serif;
  font-size: 23px;
  font-weight: 400;
  color: #0B4182;
}
.faq-header {
  text-align: center;
  text-transform: none;
  font-family: 'Ubuntu', 'Arial', sans-serif;
}
.tokens-header {
  text-align: center;
  text-transform: none;
  font-family: 'Ubuntu', 'Arial', sans-serif;
}
.cards-header {
  margin-top: 40px;
  text-align: center;
  text-transform: none;
  font-family: 'Ubuntu', 'Arial', sans-serif;
}

.dashboard-header {
  margin-top: 100px;
  text-align: center;
  text-transform: none;
  font-family: 'Ubuntu', 'Arial', sans-serif;
  font-size: 26px;
  font-weight: 400;
  color: #0B4182;
  margin-bottom: 30px;
  padding-left: 20px;
}
.liqnodes-header {
  margin-top: 50px;
  text-align: center;
  text-transform: none;
  font-family: 'Ubuntu', 'Arial', sans-serif;
  font-size: 26px;
  font-weight: 400;
  color: #0B4182;
  margin-bottom: 20px;
  padding-left: 20px;
}

.sitemap-header {
  margin-top: 10px;
  text-align: center;
  text-transform: none;
  font-family: 'Ubuntu', 'Arial', sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: #0B4182;
  margin-bottom: 20px;
}

.sub-header {
  padding-left: 20px;
  padding-bottom: 30px;
  font-size: 15px !important;
}

/* Border for the entire table */
.table-bordered-custom th, .table-bordered-custom td {
  border-right: 1px solid #dcdcdc;  /* Thin grey lines for all columns */
  border-top: 1px solid #dcdcdc;    /* Thin top border */
  border-bottom: 1px solid #dcdcdc; /* Thin bottom border */
}

/* Thicker border for the first column and vertical left border */
.table-bordered-custom th:first-child, 
.table-bordered-custom td:first-child {
  border-left: 1px solid #dcdcdc;       /* Thicker left border */
  border-right: 1px solid #dcdcdc;      /* Thicker right border */
}

/* Thicker bottom border for the first row */
.table-bordered-custom th {
  border-bottom: 1px solid #888;     /* Thicker bottom border for the first row */
}

.table-responsive {
  padding-bottom: 30px;
}

.no-border td {
  border-left: none;
  border-right: none;
}


/* CrossChain Wave Swap*/
.wscard {
  position: relative;
  width: 90%;
  border-radius: 10px;
  background: linear-gradient(0deg, rgba(125, 167, 240, 0.72) 0%, rgba(125, 167, 240, 0) 100%);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);*/
  /*border: 0.5px solid rgba(255, 255, 255, 0.5);*/
}

.mctable {
  text-align: center;
  font-family: 'Ubuntu', 'Arial', sans-serif;
  margin: 10px;
  padding-top: 30px;
  max-width: 1200px; /* Set a maximum width */
  margin: auto; /* Center the app */
}

.rmtable {
  text-align: center;
  font-family: 'Ubuntu', 'Arial', sans-serif;
  margin: 10px;
  padding: 0;
  max-width: 1200px; /* Set a maximum width */
  margin: auto; /* Center the app */
}
.page-12 {
  text-align: center;
  font-family: 'Ubuntu', 'Arial', sans-serif;
  margin: 10px;
  padding: 0;
  max-width: 1200px; /* Set a maximum width */
  margin: auto; /* Center the app */
}

.faqtable {
  text-align: center;
  font-family: 'Ubuntu', 'Arial', sans-serif;
  margin: 10px;
  padding: 0;
  max-width: 1200px; /* Set a maximum width */
  margin: auto; /* Center the app */
}

.cardtable {
  text-align: center;
  font-family: 'Ubuntu', 'Arial', sans-serif;
  margin: 10px;
  padding: 0;
  max-width: 1200px; /* Set a maximum width */
  margin: auto; /* Center the app */
}
.tokenstable {
  text-align: center;
  font-family: 'Ubuntu', 'Arial', sans-serif;
  margin: 10px;
  padding: 0;
  max-width: 1200px; /* Set a maximum width */
  margin: auto; /* Center the app */
}


/* FAQ */
.faq {
  padding: 5px 5px; /* Adjust padding for the FAQ section */
  background-color: var(--bgcolor); /* Set the background color of the FAQ section */
}

.waccordion-button {
  background-color: var(--bgcolor);
  border: none; /* Remove default border */
  color: #0B4182; /* Text color */
}

.waccordion-button:hover,
.waccordion-button:focus {
  background-color: #d9e7ff; /* Light blue background on hover */
  color: #0B4182; /* Ensure text color remains */
}

.waccordion-body {
  padding: 15px; /* Padding inside the accordion body */
  background-color: var(--bgcolor); /* Remove background color for body */
  text-align: left;
}

/* Remove borders and shadows */
.accordion-item {
  border: none; /* Remove border */
}

.accordion-item:last-child .accordion-body {
  border-bottom: none; /* Remove border from last item's body */
}

.accordion-button:focus {
  z-index: 3;
  outline: 0;
  /* box-shadow: var(--bs-accordion-btn-focus-box-shadow);*/
}

.alert {
  padding: 20px;
}


.text-center-three {
  font-weight: 600; /* Make the text bold */
  font-family: 'Ubuntu', sans-serif; /* Specify the Ubuntu font */
}
.text-center {
  font-weight: 300; /* Make the text bold */
  font-family: 'Ubuntu', sans-serif; /* Specify the Ubuntu font */
}

.ampersand {
  padding-left: 10px;  /* Add left padding to the "&" */
  padding-right: 10px; /* Add right padding to the "&" */
}
.read-more-container {
  min-height: 40px; /* Minimum height to keep layout consistent */
  display: flex;
  justify-content: center; /* Center the button horizontally */
  align-items: center; /* Center the button vertically */
}

.read-more-container {
  min-height: 40px; /* Ensures consistent height across items */
  display: flex;
  justify-content: center; /* Center the button horizontally */
  align-items: center; /* Center the button vertically */
  margin-bottom: 20px; /* Adds space below the button */
}

.empty-button {
  visibility: hidden; /* Hide the button, but maintain the same height */
  margin-bottom: 20px; /* Ensures the same spacing below items without a button */
}


/* Marquee Section*/

.marquee-section {
  overflow: hidden;
  white-space: nowrap;
}

.marquee {
  display: flex;
  animation: scroll 20s linear infinite;
}
.marquee:hover {
  animation-play-state: paused;
}

.marquee-content {
  display: flex;
  gap: 40px;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}



.mediumButton{
  font-size: 12px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
  color: var(--maincolor);
  border: 0px;
  padding: 8px 25px;
  border-radius: 30px;
  margin-top: -30px !important;
  transition: all 0.7s;
}

.mediumButton:hover{
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.3s;
  color: var(--maincolor);
  box-shadow:0 4px 24px 0 rgba(0,0,0,.1);
}

.claimButton{
  font-size: 12px;
  text-align: center;
  background: rgba(125, 167, 240, 0.44);
  color: #0B4182;
  border: 0px;
  cursor: pointer;
  padding: 8px 25px;
  border-radius: 5px;
  margin-top: -0px !important;
  margin-left: -50px !important;
  transition: all 0.3s ease-in-out;
  text-decoration: none; /* Remove underline from the link */
}

.claimButton:hover{
  background: var(--seccolor3);
  transition: all 0.3s ease-in-out;
  color: #ffffff;
  box-shadow:0 4px 24px 0 rgba(0,0,0,.1);
}

.claimButton3{
  font-size: 12px;
  text-align: center;
  background: rgba(125, 167, 240, 0.44);
  color: #0B4182;
  border: 0px;
  cursor: pointer;
  padding: 8px 25px;
  border-radius: 5px;
  margin-top: -40px !important;
  margin-left: -50px !important;
  transition: all 0.3s ease-in-out;
  text-decoration: none; /* Remove underline from the link */
}

.claimButton3:hover{
  background: var(--seccolor3);
  transition: all 0.3s ease-in-out;
  color: #ffffff;
  box-shadow:0 4px 24px 0 rgba(0,0,0,.1);
}

.start-button {
  display: inline-block; /* Make it behave like a button */
  padding: 5px 20px;
  font-size: 0.9rem;
  background: rgba(64, 162, 145, 0.44);
  color: #0B4182;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  text-decoration: none; /* Remove underline from the link */
}

.start-button:hover {
  transition: all 0.3s ease-in-out;
  color: #ffffff;
  background: rgba(64, 162, 146, 0.906);
}

.form-label {
  font-weight: 400;
  display: block;
  font-size: 15px;
  margin-bottom: 5px;
  color: #686868;
  text-align: left;
}

.form-text {
  font-weight: 500;
  display: block;
  font-size: 13px;
  margin-bottom: 5px;
  color: #777777;
  text-align: left;
}

.waweSwap .form-control::placeholder {
  color: #bbbbbb;
  opacity: 1; /* Firefox */
}

.waweSwap .form-control::-ms-input-placeholder { /* Edge 12 -18 */
  color: #bbbbbb;
}

.waweSwap .form-control:disabled {
  background-color: #e9ecef;
  color: #6c757d;
  padding: 4px !important;
}

.waweSwap .form-control-small{
  display: block;
  padding: 5px !important;
}

.waweSwap .form-control{
  border: 0px;
  background-color: transparent;
  box-shadow: none !important;
  text-align: left;
  text-align-last: left;
  font-size: 14px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  color: #777777;
}

.form-select, .form-control{
  border: 0.5px solid rgb(125, 167, 240);
  background-color: #fafafa;
  box-shadow: none !important;
  text-align: left;
  text-align-last: left;
  font-size: 14px !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  padding-left: 5px !important;
  color: #777777;
}
.dropdown-prop {
  max-width: 360px;
}

.swapProgress {
  height: 8px;
  border-radius: 10px;
  margin-top: 5px;
  background-color: #dedede;
}
.swapProgress .bg-info{
  background-color: var(--maincolor) !important;
}


.dropdownStyle{
  padding: 10px; 
  border:0px;
  border-radius: 10px;
  margin:0 auto;
  appearance: none;
  color: var(--maincolor);
  background-color:#FFFFFF;
  width: 100%;
  font-weight:400;
  font-size:13px;
  margin-top: 0.25rem;
  cursor:pointer;
  box-shadow:rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  text-align-last: center; 
  text-align: center;
}

.dropdownMenuStyle{
  width: 100%;
  border: 0px;
  box-shadow:rgba(99, 99, 99, 0.3) 0px 2px 8px 0px !important;
  border-radius: 20px;
}

.dropdownStyle:hover{
  background-color:rgb(125, 167, 240);
}
.dropdownStyle:disabled{
  background-color:#FFFFFF;
  color: var(--maincolor);
  opacity: 1;
}
.dropdownStyle:disabled.dropdown-toggle::after{
  color:#FFFFFF !important;
}

.numCircle{
  display: block;
  color: var(--maincolor);
  border: 1px solid var(--maincolor);
  font-size: 15px;
  height: 34px;
  width: 34px;
  border-radius: 20px;
  margin: 0 auto;
  line-height: 32px;
  margin-top: 7px;
  font-weight: bold;
}

.smallIconGray{
  display: block;
  margin-top: 0px;
  font-size: 16px;
  color: #999;
}


.infoButton{
  font-size:28px;
  text-align: center;
  color: var(--maincolor);
  background-color: transparent;
  border: 0px;
  border-radius: 50px;
  margin-top: 0px;
  transition: all 0.7s;
  position: absolute;
  right: 7px;
  margin-top: -15px;
  z-index: 10;
}



.infoButton:hover{
  
  transition: all 0.2s;
  color: var(--maincolor);
  box-shadow:0 4px 24px 0 rgba(0,0,0,.05);
  background: transparent;
}

.tooltipButton{
  font-size: 14px;
  text-align: center;
  background-color: rgba(125, 167, 240, 0.44);
  color: var(--maincolor);
  border: 0px;
  padding: 3px 6px;
  border-radius: 30px;
  margin-top: 0px;
  transition: all 0.7s;

  right: 15px;
  margin-top:-50px;
  z-index: 10;
}

.tooltipButton:hover{
  background-color: var(--secolor);
  transition: all 0.3s;
  color: var(--maincolor);
  box-shadow:0 4px 24px 0 rgba(0,0,0,.1);
}

.rewardsBox{
  background-color:  var(--maincolor);
  color: #FFFFFF;
  padding: 10px;
  border-radius: 100px;
  margin-bottom: 10px;
  margin-top: 10px;
}


.smallText{
  color: #999999;
  font-size: 12px;
}
.smallTitle{
  color: var(--maincolor);
  font-size: 14px;
}
.smallTitleSmall{
  color: var(--maincolor);
  font-size: 12px;
}

.smallestText {
  color: #444;
  font-size: 10px;
  text-align: left;
  float: left;
  margin-left: 10px;
  margin-top: 10px;
}


.large-button-blue-disabled {
  padding: 0.75rem 1.5rem !important;
  font-size: 1rem !important;
  
  color: #a7a7a7 !important;
  background-color: #f7f7f7 !important;
  border: 1px solid #c7c7c7!important;
  border-radius: 10px !important;

  cursor: not-allowed !important;
  transition: background-color 0.2s !important;
  width:100% !important;
}

.large-button-blue {
  padding: 0.75rem 1.5rem !important;
  font-size: 1rem !important;
  
  background-color: var(--maincolor) !important;
  color: #ffffff !important;
  border: 1px solid var(--maincolor) !important;
  border-radius: 10px !important;
  cursor: pointer !important;
  transition: background-color 0.2s !important;
  width:100% !important;
  transition: all 0.3s !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 3px 8px 0px !important;
}

.large-button-blue:hover {
  background-color: var(--maincolor) !important;
  color:#FFFFFF !important;
  transition: all 0.5s !important;
  box-shadow: rgba(99, 99, 99, 0.5) 0px 3px 8px 0px !important;
  
}


.accordionClosed{
  background: none;
  border: none;
  float: right;
  color: var(--maincolor);
}


.accordionActive{
  background: none;
  border: none;
  float: right;
  color: #999999;
}

.swapProgress {
  height: 8px;
  border-radius: 10px;
  margin-top: 5px;
  background-color: #dedede;
}
.swapProgress .bg-info{
  background-color: var(--maincolor) !important;
}

.step-indicator{
  font-weight: 400 !important;
  margin-bottom: 4px;
  font-size: 17px;
  margin-bottom: 10px;
  margin-top: 2px;
  text-align: center;
  display: block;
}

.site-map {
  padding: 40px 0;
}

.site-map-title {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.site-map-section-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

.site-map-list {
  list-style-type: none;
  padding: 0;
}

.site-map-link {
  color: #007bff;
  text-decoration: none;
  display: block;
  padding: 5px 0;
}

.site-map-link:hover {
  text-decoration: underline;
}

.swap-form-header {
font-weight: 400;
margin-top: 20px;
margin-bottom: 5px !important;
font-size: 20px;
color: #0B4182;
}

.bot-card-header {
  background:  rgb(125 167 240);
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.7);
  padding: 34px 30px;
  transition: all 0.5s ease-in-out;
  position: relative;
}

.summary-section .button-group {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.page-content {
  flex: 1; /* Makes it expand to full available height */
  display: flex;
  flex-direction: column;
}
.stake-form {
  height: 100vh
}
/* Ensure full height for page-container */
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 72vh;
}
.content {
  flex: 1; /* Takes up available space, pushing footer down */
  padding-bottom: 20px; /* Optional: add padding for spacing */
}

.button-center {
  display: flex;
  margin: 0 auto;
  justify-content: center; /* Centers the button horizontally */
  padding: 5px 20px;
  font-size: 1rem;
  background: rgba(125, 167, 240, 0.44);
  color: #0B4182;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  text-decoration: none; /* Remove underline from the link */
}
.gbl-cashback {
  color: green;
}

.category-filter {
  display: flex;
  justify-content: space-between; /* Aligns label and select to the sides */
  align-items: center; /* Centers label and select vertically */
  width: 260px; /* Adjust as needed */
  flex-wrap: wrap;
}

.category-label {
  margin-right: 10px; /* Adds space between label and select */
}

.prop-category {
  /*flex-grow: 1; /* Expands select box to the right */
  max-width: 298px; /* Sets a maximum width for the dropdown */
  border-radius: 3px;
  font-size: 14px;
  padding: 5px;
}
.prop-btn {
  flex-grow: 1; /* Expands select box to the right */
  max-width: 90px; /* Sets a maximum width for the button */
}
.prop-header {
  margin-top: 5px;
  max-width: 300px;
  text-align: left;
  text-transform: none;
  font-family: 'Ubuntu', 'Arial', sans-serif;
  font-size: 17px;
  font-weight: 400;
  color: #0B4182;
  margin-bottom: 5px;
  padding-left: 10px;
}

.page-containerProp {
  padding: 20px;
}

.full-width-tab-contentProp {
  width: 100%;
  background: linear-gradient(0deg, rgba(125, 167, 240, 0.17) 0%, rgba(125, 167, 240, 0) 100%);
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 8px;
}
.full-width-tab-submitProp {
  width: 100%;
  background: linear-gradient(0deg, rgba(125, 167, 240, 0.17) 0%, rgba(125, 167, 240, 0) 100%);
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 8px;
}

.hero-p.sub-header {
  text-align: center;
  margin-bottom: 20px;
}

.filters-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px; /* Space between category and status filters */
  width: 100%;
}

.category-filter {
  flex: 1; /* Takes up remaining space to align to the left */
}

.status-filter {
  display: flex;
  gap: 10px; /* Space between buttons */
}


.form-label {
  font-weight: 400;
  margin-right: 10px;
}

.status-label {
  margin-right: auto;
  text-align: right;
}

.prop-select {
  flex-grow: 1;
  max-width: 200px; /* Adjust as needed */
  width: 100%;
  border-radius: 3px;
  font-size: 14px;
  box-shadow: none !important;
  text-align: left;
  text-align-last: left;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  color: #777777;
  border-width: 1px;
  border-style: solid;
  border-color: #acabab
}

.status-buttons {
  display: flex;
  gap: 10px;
}

.proposal-content-container {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Space between the two columns */
  padding: 20px;
  background: #f4f8fb;
}

.proposal-content-left {
  flex: 2; /* Left column takes more space */
}

.proposal-content-right {
  flex: 1; /* Right column takes less space */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px; /* Space between buttons and other elements */
}

.voter-history ul {
  padding-left: 20px;
  margin-top: 10px;
}

.voter-history p,
.voter-history small {
  text-align: right;
}

.prop-btn.hero-button {
  width: 100%; /* Full-width buttons in the right column */
}
.prop-end {
  font-size: 13px;
  font-weight: 300;
  color: var(--secolor2);
}

.prop-report {
  font-size: 13px;
  font-weight: 300;
  color: #999999;
}

.subject-prop {
  height: 200px;
}
.lebel-weight {
  font-weight: 400;
}


.filter-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.category-filter {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
}

.status-filter {
  display: flex;
  gap: 10px;
}

/* Responsive adjustments for small screens */
@media (max-width: 768px) {
  .filter-container {
    flex-direction: column;
    align-items: center;
  }

  .category-filter,
  .status-filter {
    justify-content: center;
  }
}

.refBtnHelp{
  border-radius: 50%;
  width: 30px !important;
  height: 30px;
  line-height: 15px;
  position: absolute;
  top: 3px !important;
  margin-top: 0px !important;
  right: 5px;
  background-color: #5261DF;
  border-color: #5261DF;
  padding: 0px;
  padding-left: 4px;
  padding-right: 4px;
  z-index: 99999;
}

.refBtnHelp:hover{
  background-color: #23E89C;
  border-color: #23E89C;
}


.terms-link{
  text-decoration: none;
  font-weight: 300;
}


.radiospace{
  margin-right: 5px;
}

.disabled-button {
  background-color: #d3d3d3; /* Light gray background to indicate disabled state */
  color: #a9a9a9; /* Gray text color */
  cursor: not-allowed; /* Shows the "not-allowed" cursor on hover */
  opacity: 0.6; /* Adds some transparency for a disabled effect */
  pointer-events: none; /* Prevents any interactions */
}



.toastMessage {
  position: fixed;
  right: 20px;
  z-index: 100000;
  bottom: 120px;
  background-color: #66BB6A;
  border: 0px;
  text-align: left;
  color: #FFFFFF;
  padding: 5px 15px;
  border-radius: 30px;
  font-size: 15px;
}

.toastMessageError{
  background-color: #b84747;
}

.link{
  text-decoration: underline;
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-height: 80vh; /* Sets max height for modal content */
  width: 90%;
  overflow-y: auto; /* Enables vertical scrolling when content exceeds max-height */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}


.crypto-bet-container {
  max-width: 1200px;
  margin: auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.shadow-sm {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}


.confirm-button{
  background-color: var(--maincolor2);
  padding: 10px 40px; 
  margin-bottom: 10px;
  color: #FFFFFF;
}

/* MarqueeNotification.css */

.marquee-container-small {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  background-color: var(--maincolor1);
  padding: 10px 0;
  margin-top: 27px;
  position: fixed;
  z-index: 99;
  display: flex;
  align-items: center;
}

.marquee-content-small {
  /*display: inline-block;*/
  display: inline-flex;
  animation: marquee 220s linear infinite; /* Adjust speed by changing 20s */
  animation-play-state: running; /* Ensure the animation starts running */
}

.marquee-container-small:hover .marquee-content-small {
  animation-play-state: paused; /* Pause animation on hover */
}

.marquee-link {
  margin-right: 40px;
  font-size: 14px;
  color: #5261DF;
  text-decoration: none;
  transition: color 0.3s;
  display: inline-flex;
  align-items: center;
  /*color: #0C4283;*/
  font-weight: 500;
}

.marquee-link:hover {
  color: #007bff; /* Change color on hover */
}


.marquee-icon {
  vertical-align: middle;
  height: 20px;
  margin-left: 70px;
  margin-right: 8px; /* Space between icon and text */
  border-radius: 100px;
}


@keyframes scroll {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

.nav-tabs .nav-link{
  color: var(--maincolor);
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show{
  color: var(--maincolor1) !important;
}



.modern-card {
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 480px;
  margin: auto;
}

.card-title {
  font-weight: 600;
}

.section-title {
  font-size: 16px;
  margin-bottom: 8px;
  color: #333;
}

.price {
  font-size: 24px;
  color: #007bff;
}

.button-container .button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 6px;
}

.high-button {
  background-color: #28a745;
  color: white;
}

.low-button {
  background-color: #dc3545;
  color: white;
}

.finalize-button {
  background-color: #007bff;
  color: white;
  width: 100%;
}

.stats span {
  font-size: 14px;
}

.no-pending-bets {
  font-style: italic;
}

.text-red{
  color: #c20101 !important;
}


.btn-icons-primary {
  --bs-btn-color: #5261DF;
  --bs-btn-border-color: #5261DF;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5261DF;
  --bs-btn-hover-border-color: #5261DF;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5261DF;
  --bs-btn-active-border-color: #5261DF;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #5261DF;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5261DF;
  --bs-gradient: none;
  }



  .versionBtn {
    color: #777777;
    font-size: 10px;
    text-decoration: none;
    background: none;
    border: none;
  }
  
  .versionBtn:hover {
    text-decoration: underline;
    color: var(--maincolor);
    font-weight: 400;
    background: none;
    border: none;
  }



  .footer-scrollable {
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent wrapping of child elements */
  }
  
  .footer-scroll {
    display: flex; /* Use flexbox for horizontal alignment */
    gap: 10px; /* Add spacing between items */
    align-items: center; /* Align items vertically */
  }
  
  .footer-scroll a {
    display: inline-block; /* Ensure links are inline elements */
  }
  
  .footer {
    background-color: #f8f9fa; /* Customize background as needed */
    padding: 10px 15px;
  }
  
  .footer-scroll::-webkit-scrollbar {
    height: 6px; /* Customize scrollbar height */
  }
  
  .footer-scroll::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 3px; /* Rounded scrollbar thumb */
  }
  
  .footer-scroll::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker color on hover */
  }
  


  .claim-status {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  
  .status {
    display: flex;
    align-items: center;
  }
  
  .success {
    background-color: #e6ffed;
    border: 1px solid #b7eb8f;
    border-radius: 5px;
    padding: 10px;
  }
  
  .processing {
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
    border-radius: 5px;
    padding: 10px;
  }
  
  .icon {
    margin-right: 10px;
    font-size: 1.5em;
  }
  
  .success-icon {
    color: #52c41a;
  }
  
  .spinner-icon {
    color: #faad14;
    animation: spin 2s linear infinite;
  }
  
  .message {
    font-size: 1em;
    color: #595959;
  }
  
  .withdraw-component {
    padding: 10px;
  }
  
  /* Spinner Animation */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  